import React, { Dispatch, SetStateAction } from "react";
import {
  Box,
  Flex,
  Text,
  LegacyText,
  RoundedButton,
  SearchInput,
} from "flicket-ui";
import { AnimatePresence, motion } from "framer-motion";
import OutsideClickHandler from "react-outside-click-handler";
import { SubHeader as BasicSubHeader } from "~components";
import { Icon } from "~components/common/Icon";

const MotionIcon = motion(Icon);
const MotionBox = motion(Box);

type SubHeaderProps = {
  showSearch: boolean;
  toggleSearch: () => void;
  setSearch: Dispatch<SetStateAction<string>>;
  hasBackground?: boolean;
};

export const SubHeader = ({
  showSearch,
  toggleSearch,
  setSearch,
  hasBackground,
}: SubHeaderProps) => {
  return (
    <BasicSubHeader>
      <LegacyText
        fontSize={7}
        lineHeight="medium"
        fontWeight="heavy"
        color="white"
        variant="capitalize"
      >
        Events
      </LegacyText>
      <Flex alignItems="center" position="relative">
        <AnimatePresence initial={false}>
          {!showSearch && (
            <RoundedButton
              isIconOnly
              onClick={toggleSearch}
              variant={hasBackground ? "primary" : "solid"}
            >
              <MotionIcon
                icon="search"
                color={hasBackground ? "white" : "N800"}
                fontSize={6}
                initial={{ opacity: 0, rotate: 360 }}
                exit={{ opacity: 0, rotate: 360 }}
                animate={{ opacity: 1, rotate: 0 }}
              />
            </RoundedButton>
          )}
        </AnimatePresence>
        <AnimatePresence>
          {showSearch && (
            <OutsideClickHandler onOutsideClick={toggleSearch}>
              <MotionBox
                position="absolute"
                top={0}
                right={0}
                width="160px"
                borderRadius="md"
                initial={{ opacity: 0, width: 0 }}
                exit={{ opacity: 0, width: 0 }}
                animate={{ opacity: 1, width: "160px" }}
                backgroundColor="white"
              >
                <SearchInput onChange={setSearch} />
              </MotionBox>
            </OutsideClickHandler>
          )}
        </AnimatePresence>
      </Flex>
    </BasicSubHeader>
  );
};
