import { useEffect } from "react";
import {
  Box,
  Flex,
  Price,
  PrimaryButton,
  SystemProps,
  TertiaryButton,
  Text, LegacyText,
  TransparentButton,
} from "flicket-ui";
import { useRouter } from "next/router";

import { Icon, Row as StyledRow } from "~components";
import { withPropagation } from "~lib";
import { PointItem } from "~graphql/sdk";
import { useOrganization } from "~hooks";
import useCaptureClick from "~telemetry/traces/useCaptureClick";
import { organization } from "~test-setup/data";
import {
  AttributeNames,
  InteractionNames,
} from "~telemetry/enums/AttributeNames";

type RowProps = Partial<PointItem> &
  SystemProps & {
    bg?: any;
  };

export const PointRow = ({ id, name, amount, price, ...props }: RowProps) => {
  const router = useRouter();
  const { organization } = useOrganization();

  const onClick = async () => router.push("/packages/[id]", `/packages/${id}`);
  const navigateReservation = async () => {
    return router.push(
      "/packages/[id]/reservation",
      `/packages/${id}/reservation`
    );
  };

  useEffect(() => {
    void router.prefetch("/packages/[id]", `/packages/${id}`);
    void router.prefetch(
      "/packages/[id]/reservation",
      `/packages/${id}/reservation`
    );
  }, [router, id]);

  const captureClick = useCaptureClick({
    forwardToNest: true,
    pageName: "event-list-page",
  });

  const primaryButtonLabel = "Buy package";

  return (
    <StyledRow
      d="flex"
      justifyContent="space-between"
      alignItems="center"
      mb={{ _: 0, md: 3 }}
      px={{ _: 0, md: 2 }}
      pt={{ _: "6/4", md: 2 }}
      pb={{ _: "6/4", md: 2 }}
      onClick={onClick}
      {...props}
      key={id}
    >
      <Flex alignItems="center" flex="1" minW={0}>
        <Flex
          flex="1"
          flexDir="column"
          maxW={{ _: "calc(100% - 96px)", md: "auto" }}
          minW={0}
          mr={{ _: 0, md: 3 }}
        >
          <LegacyText
            as={"h1"}
            color="N800"
            lineHeight="medium"
            fontWeight={{ _: "heavy", md: "extraBold" }}
            fontSize={{ _: 2, md: 4 }}
            textTransform={{ _: "uppercase", md: "none" }}
            maxW="100%"
          >
            {name}
          </LegacyText>
          <LegacyText
            as={"h2"}
            color="N600"
            mt={{ _: 0, md: "1/2" }}
            lineHeight={{ _: "18px" as any, md: "normal" }}
            fontSize={{ _: 2, md: 3 }}
            mb={1}
          >
            {amount} {organization.point?.name} for <Price price={price} />
          </LegacyText>
        </Flex>
      </Flex>
      <Box width="24px" d={{ 0: "flex", md: "none" }}>
        <TransparentButton onClick={(e) => withPropagation(e, onClick)}>
          <Icon fontSize={6} icon="chevron-right" color="P300" />
        </TransparentButton>
      </Box>
      <Box height="36px" d={{ 0: "none", md: "flex" }}>
        <TertiaryButton
          px={3}
          py="1/2"
          onClick={(e) => withPropagation(e, onClick)}
        >
          <LegacyText fontSize={2} lineHeight={"16px" as any}>
            Information
          </LegacyText>
        </TertiaryButton>
        <PrimaryButton
          px={3}
          py="1/2"
          ml="6/4"
          onClick={captureClick(
            // eslint-disable-next-line @typescript-eslint/no-misused-promises
            (e) => withPropagation(e, navigateReservation),
            InteractionNames.BUTTON_PRIMARY,
            {
              [AttributeNames.BUTTON_LABEL]: primaryButtonLabel,
            }
          )}
        >
          <LegacyText fontSize={2} lineHeight={"16px" as any}>
            {primaryButtonLabel}
          </LegacyText>
        </PrimaryButton>
      </Box>
    </StyledRow>
  );
};
